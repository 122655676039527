export default {
  searchKey: 'ds_navSearch_searchTerm',
  // searchTerm: Alpine.$persist('').as('ds_navSearch_searchTerm').using(sessionStorage),
  searchTerm: '',
  searchType: 0,
  searchPagePath: '/search',
  on: false,

  init() {
    // console.log('init navsearch store', this.searchTerm);
    if (this.searchPagePath !== window.location.pathname)
      this.searchTerm = ''
  },

  doSearch(searchPagePath, searchTerm) {
    this.searchPagePath = searchPagePath
    this.searchTerm = searchTerm
    const q = []

    if (this.searchTerm) {
        window.sessionStorage.setItem('ds_navSearch_searchTerm', this.searchTerm)
        q.push(`q=${encodeURIComponent(this.searchTerm)}`)
    }

    if (q.length) {
      if (this.searchPagePath === window.location.pathname)
        window.location.hash = `?${q.join('&')}`

      else
        window.location.assign(`${this.searchPagePath}#?${q.join('&')}`)
    }
  },

  toggle() {
    this.on = !this.on
  },
}
