(
    function () {

        //simple timeout callback loop to wait for functions on window before calling
        function whenAvailable(name, callback) {
            var interval = 10; // ms
            window.setTimeout(function () {
                if (window[name]) {
                    callback(window[name]);
                } else {
                    whenAvailable(name, callback);
                }
            }, interval);
        }

        document.addEventListener('DOMContentLoaded', function () {
            var isDesignvalueEl = document.querySelector('[data-st-role="st-is-design-mode-value"]');
            if (isDesignvalueEl && isDesignvalueEl.value.toLowerCase() !== 'true') {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', document.querySelector('[data-st-role="st-status-json-endpoint-url"]').value);
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        var statusViewModel = JSON.parse(xhr.responseText);
                        if (statusViewModel && statusViewModel.IsLoggedIn) {
                            showLink('[data-logged-in-view]');

                            whenAvailable("customerManager", function (t) {
                                customerManager.syncActiveCustomer(false);
                            });
                        } else {
                            showLink('[data-logged-out-view]');
                            doQueryString('[data-login-link]');
                            whenAvailable("customerManager", function (t) {
                                customerManager.clearCustomer(false);
                            });

                        }
                    }
                };
                xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
                xhr.setRequestHeader('Pragma', 'no-cache');
                xhr.setRequestHeader('Expires', '0');
                xhr.send();
            } else {
                showLink('[data-logged-out-view]');

                whenAvailable("customerManager", function (t) {
                    customerManager.clearCustomer();
                });
            }

            var syncCartEvent = document.createEvent('CustomEvent');
            syncCartEvent.initCustomEvent('cartlistManager:syncCart', true, true, {});
            window.dispatchEvent(syncCartEvent);

            // whenAvailable("cartlistManager", function (t) {
            //     cartlistManager.syncCart();
            // });

        });

        function showLink(selector) {
            const matches = document.querySelectorAll(selector);
            matches.forEach(el => el.style.display = "block");
        }

        function doQueryString(selector) {
            const matches = document.querySelectorAll(selector);
            matches.forEach(el => {
                var href = el.getAttribute("href");
                var current = window.location.href
                var enc = window.encodeURIComponent(current);
                el.setAttribute("href", href + "?ReturnUrl=" + enc);
            });
        }
    }());
