'use strict'

import Cookies from 'js-cookie'
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import collapse from '@alpinejs/collapse'
import ajax from '@imacrayon/alpine-ajax'

import '../Mvc/Scripts/Shared/CustomerManager'
import '../Mvc/Scripts/Shared/login-status-refresh'
import navSearch from './navsearch'
// import searchStore from './searchStore'
import cartList from './cartlistmanager'
import dropdown from './dropdown.js'
import multiselect from './multiselect.js'
// import searchFacets from './searchfacets'
//  import searchResults from './searchresults.js'

Alpine.plugin(persist)
Alpine.plugin(collapse)
Alpine.plugin(ajax)

// must initialize persistant store items in main file
// navSearch.searchTerm = Alpine.$persist('').as('ds_navSearch_searchTerm').using(sessionStorage)
Alpine.store('navSearch', navSearch)
// searchStore.dsSearch = Alpine.$persist(searchStore.dsSearch).as('ds_searchStore_dsSearch').using(sessionStorage)
// Alpine.store('searchStore', searchStore)
cartList.list = Alpine.$persist([]).as('ds_ucommerce_cartList')
Alpine.store('cartlistManager', cartList)

Alpine.data('dropdown', dropdown)
Alpine.data('multiselect', multiselect)
// Alpine.data('searchFacets', searchFacets)
// Alpine.data('searchResults', searchResults)

window.Alpine = Alpine
Alpine.start()

if (document.readyState !== 'loading')
  init()

else
  document.addEventListener('DOMContentLoaded', init)

function init() {
  // console.log("init cartlist");
  cartList.updateUI()
}

export { Cookies }

// Will be exposed by webpack as the dayton library
// i.e. `dayton.Cookies.get('...')`

