import Alpine from "alpinejs";
import Toastify from "toastify-js";
import $ from "jquery";

var cartlistItem = function (
    sku,
    variantSku,
    listPrice,
    userPrice,
    price,
    qty,
    packaging,
    minOrder,
    orderFactor
) {
    this.Sku = sku;
    this.VariantSku = variantSku;
    this.ListPrice = listPrice;
    this.UserPrice = userPrice;
    this.Price = price;
    this.Quantity = qty;
    this.Packaging = packaging;
    this.MinOrder = minOrder;
    this.OrderFactor = orderFactor;

    this.equals = function (other) {
        return other.Sku == this.Sku && other.VariantSku == this.VariantSku;
    };
};

export default {
    cartlistKey: "ds_ucommerce_cartList",
    //list: Alpine.$persist([]).as('ds_ucommerce_cartList'), this is called in main script for alpine to initialzie local storage correctly

    init() {
        //this.list = JSON.parse(localStorage.getItem(this.cartlistKey)) || [];
        window.addEventListener("cartlistManager:syncCart", evt => {
            if (evt) {
                this.syncCart();
            }
        });

        window.addEventListener("cartlistManager:updateUI", evt => {
            if (evt && evt.detail) {
                if (evt.detail.scope) {
                    this.updateUI(evt.detail.scope);
                } else {
                    this.updateUI();
                }
            }
        });

        window.addEventListener("cartlistManager:addToList", evt => {
            if (evt && evt.detail) {
                if (evt.detail.cartItem) {
                    this.addToList(evt.detail.cartItem, true);
                }
            }
        });

        window.addEventListener("cartlistManager:removeFromList", evt => {
            if (evt && evt.detail) {
                if (evt.detail.cartItem) {
                    this.removeFromList(evt.detail.cartItem);
                }
            }
        });
    },

    addToList(newItem, emitSync = false) {
        //var newItem = new cartlistItem(sku, variantSku, listPrice, userPrice, qty, packaging, minOrder, orderFactor);

        if (this.list == null) this.list = new Array();

        var pIndex = this.findIndex(newItem);

        if (pIndex < 0) {
            this.list.push(newItem);
            //localStorage.setItem(this.cartlistKey, JSON.stringify(this.list));
        }

        this.updateUI();

        if (window.eventEmitter && emitSync) {
            var syncMiniBasketEvent = document.createEvent("CustomEvent");
            syncMiniBasketEvent.initCustomEvent("syncMiniBasket", true, true, {
                cartlist: this.list
            });
            window.eventEmitter.dispatchEvent(syncMiniBasketEvent);
        }
    },

    removeFromList(removeItem) {
        //var removeItem = new cartlistItem(sku, variantSku, listPrice, userPrice, qty, packaging, minOrder, orderFactor);

        if (this.list != null) {
            var pIndex = this.findIndex(removeItem);

            if (pIndex > -1) {
                this.list.splice(pIndex, 1);
            }
            //localStorage.setItem(this.cartlistKey, JSON.stringify(this.list));
        }

        this.updateUI();

        if (window.eventEmitter) {
            var syncMiniBasketEvent = document.createEvent("CustomEvent");
            syncMiniBasketEvent.initCustomEvent("syncMiniBasket", true, true, {
                cartlist: this.list
            });
            window.eventEmitter.dispatchEvent(syncMiniBasketEvent);
        }
    },
    clearList() {
        localStorage.removeItem(this.cartlistKey);
        this.list = [];
        this.updateUI();
    },
    // getList() {
    //     return JSON.parse(localStorage.getItem(this.this.cartlistKey)) || [];
    // },
    updateUI(scope) {
        this.updateWatchList();
        this.updateProductLinks(scope);
        this.setClickEvents(scope);
    },
    updateWatchList() {
        var count = this.list.length;

        if (count == 0) count = "";
        else count = "(" + count + ")";

        $(".cartlistCount").html(count);
    },

    updateProductLinks(scope) {
        var links = scope || $(".cartlistProductLink");
        //onsole.log('got product links for scope', links, scope);

        for (var i = 0; i < links.length; i++) {
            var updateItem = new cartlistItem(
                $(links[i]).attr("data-sku"),
                $(links[i]).attr("data-variant-sku"),
                $(links[i]).attr("data-list-price"),
                $(links[i]).attr("data-user-price"),
                $(links[i]).attr("data-quantity"),
                $(links[i]).attr("data-packaging"),
                $(links[i]).attr("data-min-order"),
                $(links[i]).attr("data-order-factor")
            );

            if (updateItem.VariantSku && updateItem.VariantSku !== "{{v.VariantSku}}") {
                var pIndex = this.findIndex(updateItem);
                //console.log('found index for cart item', pIndex, updateItem);

                if (pIndex > -1) {
                    this.hideLink(links[i], false);
                } else {
                    this.hideLink(links[i], true);
                }
            }
        }
    },

    setClickEvents(scope) {
        scope = scope || ".cartlistProductLink";
        $(".addCartLink a", scope).off("click");
        $(".addCartLink a", scope).click(function (event) {
            var parent = $(this).parents(".cartlistProductLink");

            var newItem = new cartlistItem(
                parent.attr("data-sku"),
                parent.attr("data-variant-sku"),
                parent.attr("data-list-price"),
                parent.attr("data-user-price"),
                parent.attr("data-price"),
                parent.attr("data-quantity"),
                parent.attr("data-packaging"),
                parent.attr("data-min-order"),
                parent.attr("data-order-factor")
            );

            Toastify({
                text: "Adding item to cart...",
                duration: 1000,
                close: false,
                gravity: "bottom", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                backgroundColor: "linear-gradient(to right, #17a2b8, #17a2b8)",
                className: "alert alert-info",
                stopOnFocus: false // Prevents dismissing of toast on hover
                //onClick: function(){} // Callback after click
            }).showToast();

            // Google Analytics
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                    add: {
                        actionField: {
                            list: "Shopping cart"
                        },
                        products: [
                            {
                                id: newItem.Sku,
                                name: parent.attr("data-name"),
                                category: "",
                                variant: newItem.VariantSku,
                                brand: parent.attr("data-brand"),
                                quantity: newItem.Quantity,
                                dimension3: "Ecommerce",
                                metric5: 12,
                                metric6: 1002
                            }
                        ]
                    }
                }
            });

            fetch("/authapi/cartlist/add", {
                method: "post",
                //mode: 'cors', // no-cors, *cors, same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json"
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(newItem)
            })
                .then(function (response) {
                    if (response.ok) {
                        response.json().then(function (res) {
                            Alpine.store("cartlistManager").addToList(newItem, true);
                            let displaymessage = "Added item to cart";
                            //console.log('got cart list add response', res);
                            if (res && res.length) {
                                Toastify({
                                    text: res,
                                    duration: 50000,
                                    close: true,
                                    gravity: "top", // `top` or `bottom`
                                    position: "center", // `left`, `center` or `right`
                                    backgroundColor: "linear-gradient(to right, #856404, #856404)",
                                    stopOnFocus: true // Prevents dismissing of toast on hover
                                    //onClick: function(){} // Callback after click
                                }).showToast();
                            }

                            Toastify({
                                text: displaymessage,
                                duration: 5000,
                                close: false,
                                gravity: "bottom", // `top` or `bottom`
                                position: "right", // `left`, `center` or `right`
                                backgroundColor: "linear-gradient(to right, #28a745, #28a745)",
                                className: "alert alert-info",
                                stopOnFocus: false // Prevents dismissing of toast on hover
                                //onClick: function(){} // Callback after click
                            }).showToast();
                        });
                    } else {
                        response.json().then(function (error) {
                            // do something with the error response
                            //console.log(error);
                            let message = "";
                            if (error.Message) {
                                message = error.Message;
                            } else if (error.ExceptionMessage) {
                                message = "Error adding item to cart";
                            }

                            Toastify({
                                text: message,
                                duration: 5000,
                                close: true,
                                gravity: "top", // `top` or `bottom`
                                position: "center", // `left`, `center` or `right`
                                backgroundColor: "linear-gradient(to right, #dc3545, #dc3545)",
                                className: "alert alert-danger",
                                stopOnFocus: true // Prevents dismissing of toast on hover
                                //onClick: function(){} // Callback after click
                            }).showToast();
                        });
                    }
                })
                .catch(function (error) {
                    //console.log('rror adding item to cart', error)
                    Toastify({
                        text: "Error adding item to cart",
                        duration: 5000,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "center", // `left`, `center` or `right`
                        backgroundColor: "linear-gradient(to right, #dc3545, #dc3545)",
                        className: "alert alert-danger",
                        stopOnFocus: true // Prevents dismissing of toast on hover
                        //onClick: function(){} // Callback after click
                    }).showToast();
                });

            event.preventDefault();

            return false;
        });

        $(".removeCartLink a", scope).off("click");
        $(".removeCartLink a", scope).click(function (event) {
            var parent = $(this).parents(".cartlistProductLink");

            var removeItem = new cartlistItem(
                parent.attr("data-sku"),
                parent.attr("data-variant-sku"),
                parent.attr("data-list-price"),
                parent.attr("data-user-price"),
                parent.attr("data-price"),
                parent.attr("data-quantity"),
                parent.attr("data-packaging"),
                parent.attr("data-min-order"),
                parent.attr("data-order-factor")
            );

            Toastify({
                text: "Removing item from cart...",
                duration: 1000,
                close: false,
                gravity: "bottom", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                backgroundColor: "linear-gradient(to right, #17a2b8, #17a2b8)",
                className: "alert alert-info",
                stopOnFocus: false // Prevents dismissing of toast on hover
                //onClick: function(){} // Callback after click
            }).showToast();

            // Google Analytics
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eec.remove",
                ecommerce: {
                    add: {
                        actionField: {
                            list: "Shopping cart"
                        },
                        products: [
                            {
                                id: removeItem.Sku,
                                name: parent.attr("data-name"),
                                category: "",
                                variant: removeItem.VariantSku,
                                brand: parent.attr("data-brand"),
                                quantity: removeItem.Quantity,
                                dimension3: "Ecommerce",
                                metric5: 12,
                                metric6: 1002
                            }
                        ]
                    }
                }
            });

            fetch("/authapi/cartlist/remove", {
                method: "post",
                //mode: 'cors', // no-cors, *cors, same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json"
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(removeItem)
            })
                .then(function (response) {
                    if (response.ok) {
                        Alpine.store("cartlistManager").removeFromList(removeItem);

                        Toastify({
                            text: "Removed item from cart",
                            duration: 2000,
                            close: false,
                            gravity: "bottom", // `top` or `bottom`
                            position: "right", // `left`, `center` or `right`
                            backgroundColor: "linear-gradient(to right, #28a745, #28a745)",
                            className: "alert alert-info",
                            stopOnFocus: false // Prevents dismissing of toast on hover
                            //onClick: function(){} // Callback after click
                        }).showToast();
                    } else {
                        response.json().then(function (error) {
                            // do something with the error response
                            Toastify({
                                text: "Error removing item from cart: " + error.ExceptionMessage,
                                duration: 5000,
                                close: true,
                                gravity: "top", // `top` or `bottom`
                                position: "center", // `left`, `center` or `right`
                                backgroundColor: "linear-gradient(to right, #dc3545, #dc3545)",
                                className: "alert alert-danger",
                                stopOnFocus: true // Prevents dismissing of toast on hover
                                //onClick: function(){} // Callback after click
                            }).showToast();
                        });
                    }
                })
                .catch(function (error) {
                    Toastify({
                        text: "Error removing item from cart: " + error,
                        duration: 5000,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "center", // `left`, `center` or `right`
                        backgroundColor: "linear-gradient(to right, #dc3545, #dc3545)",
                        className: "alert alert-danger",
                        stopOnFocus: true // Prevents dismissing of toast on hover
                        //onClick: function(){} // Callback after click
                    }).showToast();
                });
            event.preventDefault();

            return false;
        });

        $("input.cart-link-quantity").change(function (event) {
            var el = $(this);
            var sku = el.attr("data-sku");
            var variantSku = el.attr("data-variant-sku");
            var val = el.val();

            $(
                ".cartlistProductLink[data-sku='" +
                sku +
                "'][data-variant-sku='" +
                variantSku +
                "']"
            ).attr("data-quantity", val);

            event.preventDefault();

            return false;
        });
    },

    hideLink(elem, isAdd) {
        if (isAdd) {
            $(elem)
                .find(".addCartLink")
                .removeClass("hide");
            $(elem)
                .find(".removeCartLink")
                .addClass("hide");
        } else {
            $(elem)
                .find(".addCartLink")
                .addClass("hide");
            $(elem)
                .find(".removeCartLink")
                .removeClass("hide");
        }
    },

    findIndex(cartlistItem) {
        var index = -1;
        for (var i = 0; i < this.list.length; i++) {
            if (
                this.list[i]["Sku"] == cartlistItem.Sku &&
                this.list[i]["VariantSku"] == cartlistItem.VariantSku
            ) {
                index = i;
                break;
            }
        }
        return index;
    },

    getCookie(name) {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return empty string if not found
        return "";
    },

    deleteCookie(name) {
        document.cookie = name + "=; max-age=0";
    },

    setCookie(name, value, daysToLive) {
        // Encode value in order to escape semicolons, commas, and whitespace
        var cookie = name + "=" + encodeURIComponent(value);

        if (typeof daysToLive === "number") {
            /* Sets the max-age attribute so that the cookie expires
            after the specified number of days */
            cookie += "; max-age=" + daysToLive * 24 * 60 * 60;

            document.cookie = cookie;
        }
    },

    syncCart() {
        var basketId = this.getCookie("basketid");

        let params = {
            basketId: basketId
        };

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
            .join("&");

        fetch("/authapi/cartlist/sync?" + query, {})
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data) {
                    Alpine.store("cartlistManager").clearList();

                    if (data.status == "warning") {
                        if (typeof data.data.forEach === "function") {
                            data.data.forEach(item => {
                                var cartItem = new cartlistItem(
                                    item.Sku,
                                    item.VariantSku,
                                    item.ListPrice,
                                    item.UserPrice,
                                    item.Price,
                                    item.Quantity,
                                    item.Packaging,
                                    item.MinOrder,
                                    item.OrderFactor
                                );
                                Alpine.store("cartlistManager").addToList(cartItem);
                            });

                            if (window.eventEmitter) {
                              var syncMiniBasketEvent = document.createEvent("CustomEvent");
                              syncMiniBasketEvent.initCustomEvent("syncMiniBasket", true, true, {
                                  cartlist: this.list
                              });
                              window.eventEmitter.dispatchEvent(syncMiniBasketEvent);
                          }
                        }
                    } else {
                        // data is an exception object if not 200
                        if (data != "No basket" && typeof data.forEach === "function") {
                            data.forEach(item => {
                                var cartItem = new cartlistItem(
                                    item.Sku,
                                    item.VariantSku,
                                    item.ListPrice,
                                    item.UserPrice,
                                    item.Price,
                                    item.Quantity,
                                    item.Packaging,
                                    item.MinOrder,
                                    item.OrderFactor
                                );
                                Alpine.store("cartlistManager").addToList(cartItem);
                            });

                            if (window.eventEmitter) {
                              var syncMiniBasketEvent = document.createEvent("CustomEvent");
                              syncMiniBasketEvent.initCustomEvent("syncMiniBasket", true, true, {
                                  cartlist: this.list
                              });
                              window.eventEmitter.dispatchEvent(syncMiniBasketEvent);
                          }
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        this.updateUI();
    },

    syncCartItems(cart) {
        if (cart) {
            this.clearList();
            for (var i = 0; i < cart.OrderLines.length; i++) {
                var line = cart.OrderLines[i];
                this.addToList(
                    line.Sku,
                    line.VariantSku,
                    line.ListPrice,
                    line.UserPrice,
                    line.Price,
                    line.Quantity,
                    line.Packaging,
                    line.MinOrder,
                    line.OrderFactor
                );
            }

            if (window.eventEmitter) {
              var syncMiniBasketEvent = document.createEvent("CustomEvent");
              syncMiniBasketEvent.initCustomEvent("syncMiniBasket", true, true, {
                  cartlist: this.list
              });
              window.eventEmitter.dispatchEvent(syncMiniBasketEvent);
          }
        }
    }
};
