export default ({ dropdownName = 'dropdown', panelItemClass = 'show', buttonClass = 'active', mouseLeave = true }) => ({
  open: false,
  dropdownName,
  init() {
    this.open = false
    this.$watch('open', (value) => {
      // console.log(`${dropdownName} dropdown open`, value)
    },
    )
  },
  toggle() {
    if (this.open)
      return this.closeDropdown()

    this.open = true
  },
  closeDropdown(focusAfter) {
    if (!this.open)
      return

    this.open = false

    return focusAfter && focusAfter.focus()
  },
  menuItem: {
    '@keydown.escape.prevent.stop': function () {
      this.closeDropdown(this.$refs[`${dropdownName}_button`])
    },
    'x-id': function () {
      return [`${dropdownName}_dropdown-button`]
    },
    '@mouseleave': function () {
      if (mouseLeave)
        this.open = false
    },
  },
  button: {
    'x-ref': function () {
      return `${dropdownName}_button`
    },
    '@click': function () {
      this.toggle()
    },
    ':aria-expanded': function () {
      return this.open
    },
    ':aria-controls': function () {
      return this.$id(`${dropdownName}_dropdown-button`)
    },
    ':class': function () {
      return this.open ? buttonClass : ''
    },
  },
  panel: {
    'x-ref': function () {
      return `${dropdownName}_panel`
    },
    'x-show': function () {
      return this.open
    },
    'x-transition.origin.top.left': function () {

    },
    '@click.outside': function () {
      this.closeDropdown(this.$refs[`${dropdownName}_button`])
    },
    ':id': function () {
      return this.$id(`${dropdownName}_dropdown-button`)
    },
  },
  panelItem: {
    ':class': function () {
      return this.open ? panelItemClass : ''
    },
  },
})
